<template>
  <div>
    <h2 class="content-block">Bestellen</h2>

    <DxDataGrid
      class="dx-card wide-card"
      ref="orderDataGridComponent"
      :data-source="orderGridDataSource"
      :show-borders="true"
      :focused-row-enabled="false"
      :column-auto-width="true"
      :column-hiding-enabled="true"
      :allowColumnReordering="false"
      :allowColumnResizing="true"
      :row-alternation-enabled="true"
      :show-column-lines="true"
      :show-row-lines="true"
      @cell-prepared="onCellPrepared"
    >
      <DxFilterRow :visible="true" />
      <DxHeaderFilter :visible="true" />

      <DxToolbar>
        <DxItem location="before">
          <DxSelectBox
            ref="selectBoxOptionsTourComponent"
            label="Tourenauswahl"
            width="180"
            labelMode="floating"
            placeholder="Wähle eine Tour"
            valueExpr="tourID"
            :data-source="tourSelectBoxDataSource"
            :display-Expr="selectBoxTourDisplayExpression"
            @value-changed="selectBoxTourValueChangedEvent"
          />
        </DxItem>

        <DxItem location="before">
          <DxSelectBox
            ref="selectBoxOptionsArtikelGruppenComponent"
            width="180"
            label="Artikelgruppe"
            labelMode="floating"
            placeholder="Wähle eine Artikelgruppe"
            value="Alle Artikel"
            :data-source="artikelGruppenSelectBoxDataSource"
            @value-changed="selectBoxArtikelGruppenValueChangedEvent"
          />
        </DxItem>

        <DxItem>
          <DxSearchPanel :visible="true" :width="240" placeholder="Suche..." />
        </DxItem>

        <DxItem>
          <DxButton
            width="240"
            ref="buttonWarenkorb"
            text="Warenkorb anzeigen"
            type="success"
            styling-mode="outlined"
            @click="warenkorbToggle"
          />
        </DxItem>

        <DxItem>
          <DxButton
            width="180"
            text="Bestellen"
            type="danger"
            styling-mode="outlined"
            @click="bestellen"
          />
        </DxItem>
      </DxToolbar>

      <DxEditing
        mode="cell"
        :allow-adding="false"
        :allow-deleting="false"
        :allow-updating="true"
      />

      <dx-paging :page-size="10" />
      <dx-pager :show-page-size-selector="true" :show-info="true" />
      <dx-filter-row :visible="true" />

      <dx-column
        data-field="artikelnummer"
        caption="Artikelnummer"
        data-type="string"
        :allow-editing="false"
        :visible="false"
      />

      <dx-column
        dataField="kzVerfuegbar"
        caption="Verfügbarkeit"
        data-type="string"
        :allow-editing="false"
        :calculate-cell-value="kzVerfuegbarCellFunction"
        :visible="!userData.kzAnzeigeVerfuegbareMenge"
      />

      <dx-column
        data-field="artikelbezeichnung"
        caption="Bezeichnung"
        data-type="string"
        :allow-editing="false"
      />

      <dx-column
        data-field="adresseHersteller"
        caption="Hersteller"
        data-type="string"
        :allow-editing="false"
      />

      <dx-column
        data-field="artikelgruppe"
        caption="Artikelgruppe"
        data-type="string"
        :allow-editing="false"
        :visible="false"
      />

      <dx-column
        data-field="mengeVerfuegbar"
        caption="verfügbare Menge KW"
        :data-type="string"
        :allow-editing="false"
        :calculate-cell-value="mengeVerfuegbarCellFunction"
        :visible="userData.kzAnzeigeVerfuegbareMenge"
      />

      <dx-column
        data-field="mengeVerfuegbarKW1"
        caption="verfügbare Menge KW"
        :data-type="string"
        :allow-editing="false"
        :calculate-cell-value="mengeVerfuegbarKW1CellFunction"
        :visible="userData.kzAnzeigeVerfuegbareMenge"
      />

      <dx-column
        data-field="mengeVerfuegbarKW2"
        caption="verfügbare Menge KW"
        :data-type="string"
        :allow-editing="false"
        :calculate-cell-value="mengeVerfuegbarKW2CellFunction"
        :visible="userData.kzAnzeigeVerfuegbareMenge"
      />

      <dx-column
        data-field="mengeVPE"
        caption="Menge/VPE"
        data-type="number"
        :allow-editing="false"
        :calculate-cell-value="mengeVPECellFunction"
      />

      <dx-column
        data-field="eingabebestellmengeVPE"
        caption="Eingabe Bestellmenge/VPE"
        data-type="number"
        :allow-editing="true"
        :allow-updating="true"
        :validation-callback="validateCellEingabeBestellMengeVPE"
      />

      <dx-column
        data-field="eingabebestellmenge"
        caption="Bestellmenge/Einheit"
        data-type="string"
        :allow-editing="false"
        :calculate-cell-value="eingabebestellmengeCellFunction"
      />

      <dx-column
        data-field="preis"
        caption="Einzelpreis"
        data-type="string"
        :allow-editing="false"
        :calculate-cell-value="preisCellFunction"
      />
    </DxDataGrid>
  </div>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DataSource from "devextreme/data/data_source";
import { DxSelectBox } from "devextreme-vue/select-box";
import notify from "devextreme/ui/notify";
import Helper from "../utils/order";
import auth from "../auth";
import DxDataGrid, {
  DxColumn,
  DxPager,
  DxPaging,
  DxItem,
  DxFilterRow,
  DxHeaderFilter,
  DxSearchPanel,
  DxToolbar,
  DxEditing,
} from "devextreme-vue/data-grid";

/**
 * Reference Keys für die UI Komponenten
 */

const orderDataGridComponent = "";
const selectBoxOptionsTourComponent = "";
const selectBoxOptionsArtikelGruppenComponent = "";
const buttonWarenkorb = "";

/**
 * Export der Vue Komponente
 */

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxToolbar,
    DxSelectBox,
    DxItem,
    DxPager,
    DxSearchPanel,
    DxButton,
    DxHeaderFilter,
    DxFilterRow,
    DxPaging,
    DxEditing,
  },

  async mounted() {
    /**
     * Frage die Daten für die UI Komponenten an
     */

    this.orderDataGridInstance.beginCustomLoading();
    await this.getUserData();
    await Helper.getTourData(this.userData.token);
    await Helper.getAlleArtikelDaten(this.userData.token);

    /**
     * Initiazles setzen von Optionen der UI Komponenten
     */

    this.tourSelectBoxInstance.option(
      "value",
      Helper.selectBoxTourDaten[0].tourID
    );

    this.filterOrderGridNachTour(Helper.selectBoxTourDaten[0].tourID);
    this.setzeArtikelGruppenDataSource();
    this.orderDataGridInstance.endCustomLoading();

    /**
     * Deaktiviere die Loader Animation nach laden des Tabels, -> mode: cell
     */

    this.orderDataGridInstance.option("loadPanel", { enabled: false });
  },

  data() {
    /**
     * DataSource Objekte für die UI Komponenten
     */

    const orderGridDataSource = new DataSource({
      key: "pK_ID",
      load: () => {
        return Helper.gridAnzeige;
      },
      update: (key, values) => {
        for (let index = 0; index < Helper.gridAnzeige.length; index++) {
          if (Helper.gridAnzeige[index].pK_ID == key) {
            Helper.gridAnzeige[index].eingabebestellmengeVPE =
              values.eingabebestellmengeVPE;

            if (values.eingabebestellmengeVPE < 1) {
              this.fillWarenKorbWithData();
            }

            break;
          }
        }
      },
    });

    const tourSelectBoxDataSource = new DataSource({
      key: "tourID",
      load: () => {
        return Helper.selectBoxTourDaten;
      },
      byKey: (key) => {
        for (let index = 0; index < Helper.selectBoxTourDaten.length; index++) {
          if (Helper.selectBoxTourDaten[index].tourID == key) {
            return Helper.selectBoxTourDaten[index];
          }
        }
      },
    });

    const artikelGruppenSelectBoxDataSource = new DataSource({
      load: () => {
        return Helper.selextBoxArtikelGruppenDaten;
      },
      byKey: () => {
        return "Alle Artikel";
      },
    });

    /**
     * User Data Konstante
     */

    const userData = {};

    /**
     * Export der Variablen an die Applikation
     */

    return {
      /**
       * Bereitstellen der UI Komponenten DataSource
       */

      orderGridDataSource,
      tourSelectBoxDataSource,
      artikelGruppenSelectBoxDataSource,

      /**
       * Bereitstellen der UI Komponenten
       */

      orderDataGridComponent,
      selectBoxOptionsTourComponent,
      selectBoxOptionsArtikelGruppenComponent,
      buttonWarenkorb,

      /**
       * Export UserData an die View Komponente
       */
      userData,
    };
  },
  methods: {
    onCellPrepared(e) {
      if (
        e.rowType == "data" &&
        e.column.dataField == "eingabebestellmengeVPE" &&
        e.data.eingabebestellmengeVPE > 0
      ) {
        e.cellElement.classList.add("eingabe");
      }
    },

    /**
     * Bestellen & Warenkorb
     */

    fillWarenKorbWithData() {
      Helper.gridWarenkorbArtikel = Helper.gridAnzeige.filter(function (el) {
        return el.eingabebestellmengeVPE > 0;
      });
      Helper.gridAnzeige = Helper.gridWarenkorbArtikel;
      this.orderDataGridInstance.refresh();
    },

    disableUIComponents() {
      this.tourSelectBoxInstance.option("disabled", true);
      this.artikelGruppenSelectBoxInstance.option("disabled", true);
    },

    enableUIComponents() {
      this.tourSelectBoxInstance.option("disabled", false);
      this.artikelGruppenSelectBoxInstance.option("disabled", false);
    },

    warenkorbToggle() {
      if (Helper.warenkorbToggleStatus == false) {
        this.disableUIComponents();

        this.buttonWarenkorbInstance.option("text", "Warenkorb ausblenden");
        this.artikelGruppenSelectBoxInstance.option("value", "Alle Artikel");

        this.orderDataGridInstance.option(
          "noDataText",
          "Es befinden sich noch keine Artikel im Warenkorb"
        );
        this.fillWarenKorbWithData();

        Helper.warenkorbToggleStatus = true;
      } else {
        this.buttonWarenkorbInstance.option("text", "Warenkorb anzeigen");

        this.enableUIComponents();
        this.filterOrderGridNachTour(Helper.selectBoxTourDaten[0].tourID);
        this.orderDataGridInstance.refresh();
        Helper.warenkorbToggleStatus = false;
      }
    },

    bestellen() {
      notify("Bestellung erfolgreich verschickt!", "success", 3000);
    },

    /**
     * Display Expression Events
     */

    selectBoxTourDisplayExpression(item) {
      if (item !== null) {
        let dtm = new Date(item.datum);
        let strWochentag;
        switch (dtm.getDay()) {
          case 0:
            strWochentag = "So";
            break;
          case 1:
            strWochentag = "Mo";
            break;
          case 2:
            strWochentag = "Di";
            break;
          case 3:
            strWochentag = "Mi";
            break;
          case 4:
            strWochentag = "Do";
            break;
          case 5:
            strWochentag = "Fr";
            break;
          case 6:
            strWochentag = "Sa";
            break;
        }
        return (
          strWochentag +
          " " +
          item.datum.substring(8, 10) +
          "." +
          item.datum.substring(5, 7) +
          "." +
          item.datum.substring(0, 4)
        );
      }
    },

    /**
     * Value Change Events
     */

    selectBoxArtikelGruppenValueChangedEvent(item) {
      this.filterOrderGridNachArtikelGruppe(item.value);
    },

    selectBoxTourValueChangedEvent(item) {
      this.filterOrderGridNachTour(item.value);
    },

    /**
     * Get User Data
     */

    async getUserData() {
      await auth.getUser().then((e) => {
        this.userData = e.data;
      });
    },

    /**
     * Filter Grid Functions
     * */

    filterOrderGridNachArtikelGruppe(ChosenArtikelGruppe) {
      var objGridAnzeigeNeu = [];
      Helper.gridAnzeigeTour.forEach(function (item) {
        if (
          item.artikelgruppe === ChosenArtikelGruppe ||
          ChosenArtikelGruppe === "Alle Artikel"
        ) {
          objGridAnzeigeNeu.push(item);
        }
      });
      Helper.gridAnzeige = objGridAnzeigeNeu;
      this.orderDataGridInstance.refresh();
    },

    filterOrderGridNachTour(tourID) {
      Helper.gridAnzeige = Helper.gridAlleArtikelDaten.filter(function (el) {
        return el.tourID === tourID;
      });
      Helper.gridAnzeige.sort((a, b) => (a.sortierung < b.sortierung ? -1 : 1));
      Helper.gridAnzeigeTour = Helper.gridAnzeige;

      this.orderDataGridInstance.refresh();
    },

    setzeArtikelGruppenDataSource() {
      var artikelGruppen = ["Alle Artikel"];
      var gridAlleArtikelDatenCopy = Helper.gridAlleArtikelDaten;
      for (var i = 0; i < gridAlleArtikelDatenCopy.length; i++) {
        if (
          !artikelGruppen.includes(gridAlleArtikelDatenCopy[i].artikelgruppe)
        ) {
          artikelGruppen.push(gridAlleArtikelDatenCopy[i].artikelgruppe);
        }
      }
      Helper.selextBoxArtikelGruppenDaten = artikelGruppen;
    },

    /**
     * Cell Validator
     */

    validateCellEingabeBestellMengeVPE(options) {
      if (options.data.maxMengeVPE < options.value) {
        return false;
      } else {
        return true;
      }
    },

    /**
     * Zell Funktionen der Grid Spalten
     */

    kzVerfuegbarCellFunction(rowData) {
      if (rowData.kzVerfuegbar === 1) {
        return "";
      } else {
        return "Nicht verfügbar";
      }
    },
    mengeVerfuegbarCellFunction(rowData) {
      if (rowData.mengeVerfuegbar < 0) {
        return "0 " + rowData.einheit;
      } else if (
        rowData.maxAnzeigeMenge < rowData.mengeVerfuegbar &&
        rowData.maxAnzeigeMenge > 0
      ) {
        return rowData.maxAnzeigeMenge + " " + rowData.einheit;
      } else {
        return rowData.mengeVerfuegbar + " " + rowData.einheit;
      }
    },
    mengeVerfuegbarKW1CellFunction(rowData) {
      if (rowData.mengeVerfuegbarKW1 < 0) {
        return "0 " + rowData.einheit;
      } else if (
        rowData.maxAnzeigeMenge < rowData.mengeVerfuegbarKW1 &&
        rowData.maxAnzeigeMenge > 0
      ) {
        return rowData.maxAnzeigeMenge + " " + rowData.einheit;
      } else {
        return rowData.mengeVerfuegbarKW1 + " " + rowData.einheit;
      }
    },
    mengeVerfuegbarKW2CellFunction(rowData) {
      if (rowData.mengeVerfuegbarKW2 < 0) {
        return "0 " + rowData.einheit;
      } else if (
        rowData.maxAnzeigeMenge < rowData.mengeVerfuegbarKW2 &&
        rowData.maxAnzeigeMenge > 0
      ) {
        return rowData.maxAnzeigeMenge + " " + rowData.einheit;
      } else {
        return rowData.mengeVerfuegbarKW2 + " " + rowData.einheit;
      }
    },
    mengeVPECellFunction(rowData) {
      return rowData.mengeVPE + " " + rowData.einheit;
    },
    eingabebestellmengeCellFunction(rowData) {
      if (rowData.eingabebestellmengeVPE > 0 && rowData.mengeVPE > 0) {
        return (
          rowData.eingabebestellmengeVPE * rowData.mengeVPE +
          " " +
          rowData.einheit
        );
      } else if (
        typeof rowData.eingabebestellmengeVPE === "undefined" ||
        rowData.eingabebestellmengeVPE === 0
      ) {
        return "0" + " " + rowData.einheit;
      } else {
        return "0 " + rowData.einheit;
      }
    },
    preisCellFunction(rowData) {
      let einzelpreis = parseFloat(rowData.preis);
      return String(einzelpreis.toFixed(2)) + "€";
    },
  },
  computed: {
    orderDataGridInstance: function () {
      return this.$refs.orderDataGridComponent.instance;
    },
    tourSelectBoxInstance: function () {
      return this.$refs.selectBoxOptionsTourComponent.instance;
    },
    artikelGruppenSelectBoxInstance: function () {
      return this.$refs.selectBoxOptionsArtikelGruppenComponent.instance;
    },
    buttonWarenkorbInstance: function () {
      return this.$refs.buttonWarenkorb.instance;
    },
  },
};
</script>

<style>
.eingabe {
  background-color: #dcecca !important;
}
</style>
