export default {
  API_URL: 'https://test.regional-bio.de:3003',
  testDataSource: ["Alle Artikel"],
  selectBoxTourDaten: [],
  selextBoxArtikelGruppenDaten: [],
  gridAlleArtikelDaten: [],
  gridAnzeige: [],
  gridAnzeigeTour: [],
  gridAlleArtikelFilter: [],
  gridWarenkorbArtikel: [],
  warenkorbToggleStatus: false,

  getTourData: async function(token){
    await fetch(this.API_URL + "/api/vwTourShop", {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      accept: "application/json",
      Authorization: "" + token + "",
    },
  })
    .then((response) => response.json())
    .then((result) => {
      this.selectBoxTourDaten = result;
    })
    .catch(() => {
      throw "Network error";
    });
  },
  getAlleArtikelDaten: async function(token){
    await fetch(this.API_URL + "/api/vwArtikelShopLandwirtschaft", {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        Authorization: "" + token + "",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        this.gridAlleArtikelDaten = result;
        this.gridAnzeige = result;
      })
      .catch(() => {
        throw "Network error";
      });
  }
};
