export default [
  {
    text: "Home",
    path: "/home",
    icon: "home"
  },
  {
    text: "Shop",
    icon: "cart",
    items: [
      {
        text: "Bestellen",
        path: "/bestellen"
      },
      {
        text: "Anbieten",
        path: "/anbieten"
      }
    ]
  },
  {
    text: "Profil",
    icon: "card",
    items: [
      {
        text: "Einstellungen",
        path: "/profile"
      },
      {
        text: "Abmelden",
        path: "/logout"
      }
    ]
  }
];
