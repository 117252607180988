export default {
  API_URL: "https://test.regional-bio.de:3003",
  selectBoxTourDaten: [],
  selextBoxArtikelGruppenDaten: [],
  gridAlleArtikelDaten: [],
  gridAnzeige: [],
  gridAnzeigeTour: [],
  gridAlleArtikelFilter: [],
  gridWarenkorbArtikel: [],
  warenkorbToggleStatus: false,
  anbietenDaten: [],
  originalBestellMenge: [],

  /**
   * Status des aktuellen Bestellungsvorgangs
   * Neu: es wurde noch nichts bestellt
   * In Bearbeitung: Es befinden sich Angebote (Zellen) in der Bearbeitung
   * Angeboten: Click auf anbieten und positiv callback von der API
   */

  statusData: ["Angeboten", "Neu", "In Bearbeitung"],

  getTourData: async function (token) {
    await fetch(this.API_URL + "/api/vwTourShop", {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        Authorization: "" + token + "",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        this.selectBoxTourDaten = result;
      })
      .catch(() => {
        throw "Network error";
      });
  },
  getAlleArtikelDaten: async function (token) {
    await fetch(this.API_URL + "/api/vwArtikelShopLWLieferant", {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        Authorization: "" + token + "",
      },
    })
      .then((response) => response.json())
      .then((result) => {
        this.gridAlleArtikelDaten = result;
        this.gridAnzeige = result;
      })
      .catch(() => {
        throw "Network error";
      });
  },
  bieteArtikelAn: async function (body, token) {
    await fetch(this.API_URL + "/api/ShopAngebotsmengeErfassen", {
      method: "POST",
      body: JSON.stringify({
        tourID: body.tourID,
        adresseID: body.adresseID,
        artikelID: body.artikelID,
        menge: body.menge,
      }),
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        Authorization: "" + token + "",
      },
    })
      .then(() => {
      })
      .catch((e) => {
        console.log(e);
        throw "Network error";
      });
  },
};
