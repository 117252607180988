<template>
  <div>
    <div class="content-block">
      <h2>Anbieten</h2>
    </div>

    <DxDataGrid
      class="dx-card wide-card"
      ref="orderDataGridComponent"
      :data-source="orderGridDataSource"
      :show-borders="true"
      :focused-row-enabled="false"
      :column-auto-width="true"
      :column-hiding-enabled="true"
      :allowColumnReordering="false"
      :allowColumnResizing="true"
      :row-alternation-enabled="true"
      :show-column-lines="true"
      :show-row-lines="true"
      @cell-prepared="onCellPrepared"
    >
      <DxFilterRow :visible="true" />
      <DxHeaderFilter :visible="true" />

      <DxToolbar>
        <DxItem location="before">
          <DxSelectBox
            ref="selectBoxOptionsTourComponent"
            label="Tourenauswahl"
            width="180"
            labelMode="floating"
            placeholder="Wähle eine Tour"
            valueExpr="tourID"
            :data-source="tourSelectBoxDataSource"
            :display-Expr="selectBoxTourDisplayExpression"
            @value-changed="selectBoxTourValueChangedEvent"
          />
        </DxItem>

        <DxItem location="before">
          <DxSelectBox
            ref="selectBoxOptionsArtikelGruppenComponent"
            width="180"
            label="Artikelgruppe"
            labelMode="floating"
            placeholder="Wähle eine Artikelgruppe"
            value="Alle Artikel"
            :data-source="artikelGruppenSelectBoxDataSource"
            @value-changed="selectBoxArtikelGruppenValueChangedEvent"
          />
        </DxItem>

        <DxItem location="before">
          <DxSelectBox
            ref="selectBoxStatusComponent"
            width="180"
            label="Status"
            labelMode="floating"
            readOnly="false"
            :display-Expr="exampleDisplayExpression"
            :data-source="statusDataSource"
          />
        </DxItem>

        <DxItem location="after">
          <DxButton
            :width="150"
            text="Aktualisieren"
            type="normal"
            styling-mode="outlined"
            @click="aktualisiereGrid"
          />
        </DxItem>

        <DxItem>
          <DxSearchPanel :visible="true" :width="240" placeholder="Suche..." />
        </DxItem>

        <DxItem>
          <DxButton
            width="200"
            ref="buttonWarenkorb"
            text="Angebotene Artikel"
            type="success"
            styling-mode="outlined"
            @click="warenkorbToggle"
          />
        </DxItem>

        <DxItem>
          <DxButton
            width="180"
            text="Anbieten"
            type="danger"
            styling-mode="outlined"
            @click="anbieten"
          />
        </DxItem>
      </DxToolbar>

      <DxEditing
        mode="cell"
        :allow-adding="false"
        :allow-deleting="false"
        :allow-updating="true"
      />

      <dx-paging :page-size="10" />
      <dx-pager :show-page-size-selector="true" :show-info="true" />
      <dx-filter-row :visible="true" />

      <dx-column
        data-field="artikelnummer"
        caption="Artikelnummer"
        data-type="string"
        :allow-editing="false"
        :visible="false"
      />

      <dx-column
        data-field="artikelbezeichnung"
        caption="Bezeichnung"
        data-type="string"
        :allow-editing="false"
      />

      <dx-column
        data-field="artikelgruppe"
        caption="Artikelgruppe"
        data-type="string"
        :allow-editing="false"
        :visible="false"
      />

      <dx-column
        data-field="artikelID"
        caption="ArtikelID"
        data-type="number"
        :allow-editing="false"
        :visible="false"
      />

      <dx-column
        data-field="adresseID"
        caption="AdresseID"
        data-type="string"
        :allow-editing="false"
        :visible="false"
      />

      <dx-column
        data-field="bestellteMenge"
        caption="Bestellte Menge/VPE"
        :data-type="string"
        :allow-editing="false"
        :calculate-cell-value="mengeVerfuegbarCellFunction"
      />

      <dx-column
        data-field="mengeVPE"
        caption="Menge/VPE"
        data-type="number"
        :allow-editing="false"
        :calculate-cell-value="mengeVPECellFunction"
      />

      <DxColumn
        data-field="bestellmenge"
        caption="Eingabe Angebotsmenge/VPE"
        data-type="number"
        :allow-editing="true"
        :allow-updating="true"
      >
      </DxColumn>

      <dx-column
        data-field="eingabebestellmenge"
        caption="Angebotsmenge/Einheit"
        data-type="string"
        :allow-editing="false"
        :calculate-cell-value="eingabebestellmengeCellFunction"
      />

      <dx-column
        data-field="preis"
        caption="Einzelpreis"
        data-type="string"
        :allow-editing="false"
        :calculate-cell-value="preisCellFunction"
      />
    </DxDataGrid>
  </div>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DataSource from "devextreme/data/data_source";
import { DxSelectBox } from "devextreme-vue/select-box";
import notify from "devextreme/ui/notify";
import Helper from "../utils/offer";
import auth from "../auth";
import DxDataGrid, {
  DxColumn,
  DxPager,
  DxPaging,
  DxItem,
  DxFilterRow,
  DxHeaderFilter,
  DxSearchPanel,
  DxToolbar,
  DxEditing,
} from "devextreme-vue/data-grid";

/**
 * Reference Keys für die UI Komponenten
 */

const orderDataGridComponent = "";
const selectBoxOptionsTourComponent = "";
const selectBoxOptionsArtikelGruppenComponent = "";
const selectBoxStatusComponent = "";
const buttonWarenkorb = "";

/**
 * Export der Vue Komponente
 */

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxToolbar,
    DxSelectBox,
    DxItem,
    DxPager,
    DxSearchPanel,
    DxButton,
    DxHeaderFilter,
    DxFilterRow,
    DxPaging,
    DxEditing,
  },

  async mounted() {
    await this.initMethod();
  },

  data() {
    /**
     * DataSource Objekte für die UI Komponenten
     */

    const statusDataSource = new DataSource({
      key: "status",
      load: () => {
        return Helper.statusData;
      },
      byKey: () => {
        return Helper.statusData;
      },
    });

    const orderGridDataSource = new DataSource({
      key: "pK_ID",
      load: () => {
        return Helper.gridAnzeige;
      },
      update: (key, values) => {
        for (let index = 0; index < Helper.gridAnzeige.length; index++) {
          if (Helper.gridAnzeige[index].pK_ID == key) {
            //Validation: bestellmenge darf nicht kleiner als BestellteMenge sein.
            if (
              values.bestellmenge < Helper.gridAnzeige[index].bestellteMenge
            ) {
              notify(
                "Artikel die Bereits bestellt wurden können nicht zurückgezogen werden",
                "warning",
                5000
              );
              return false;
            }

            var bestellMengeVorher;
            for (let x = 0; x < Helper.originalBestellMenge.length; x++) {
              if (Helper.originalBestellMenge[x].pK_ID == key) {
                bestellMengeVorher =
                  Helper.originalBestellMenge[x].bestellmenge;
                break;
              }
            }

            // Setze Bestellmenge im Grid
            Helper.gridAnzeige[index].bestellmenge = values.bestellmenge;

            var artikelBereitsAngeboten = false;
            var updateAngebotMenge =
              parseInt(values.bestellmenge) - parseInt(bestellMengeVorher);

            if (Helper.anbietenDaten.length > 0) {
              for (var i = 0; i < Helper.anbietenDaten.length; i++) {
                if (Helper.anbietenDaten[i].pK_ID == key) {
                  Helper.anbietenDaten[i].menge = updateAngebotMenge;
                  artikelBereitsAngeboten = true;
                  break;
                }
              }
            }

            if (!artikelBereitsAngeboten) {
              Helper.anbietenDaten.push({
                pK_ID: Helper.gridAnzeige[index].pK_ID,
                tourID: Helper.gridAnzeige[index].tourID,
                adresseID: Helper.gridAnzeige[index].adresseID,
                artikelID: Helper.gridAnzeige[index].artikelID,
                menge: updateAngebotMenge,
              });
            }

            // Offer Status auf in Bearbeitung
            this.statusSelectBoxInstance.option("value", "In Bearbeitung");

            if (values.bestellmenge < 1) {
              this.fillWarenKorbWithData();
            }

            break;
          }
        }
      },
    });

    const tourSelectBoxDataSource = new DataSource({
      key: "tourID",
      load: () => {
        return Helper.selectBoxTourDaten;
      },
      byKey: (key) => {
        for (let index = 0; index < Helper.selectBoxTourDaten.length; index++) {
          if (Helper.selectBoxTourDaten[index].tourID == key) {
            return Helper.selectBoxTourDaten[index];
          }
        }
      },
    });

    const artikelGruppenSelectBoxDataSource = new DataSource({
      load: () => {
        return Helper.selextBoxArtikelGruppenDaten;
      },
      byKey: () => {
        return "Alle Artikel";
      },
    });

    /**
     * User Data Konstante
     */

    const userData = {};

    /**
     * Export der Variablen an die Applikation
     */

    return {
      /**
       * Bereitstellen der UI Komponenten DataSource
       */

      orderGridDataSource,
      tourSelectBoxDataSource,
      artikelGruppenSelectBoxDataSource,
      statusDataSource,

      /**
       * Bereitstellen der UI Komponenten
       */

      orderDataGridComponent,
      selectBoxOptionsTourComponent,
      selectBoxOptionsArtikelGruppenComponent,
      selectBoxStatusComponent,
      buttonWarenkorb,

      /**
       * Export UserData an die View Komponente
       */
      userData,
    };
  },
  methods: {
    /**
     * Cell Prepared Styles
     */

    exampleDisplayExpression() {},

    onCellPrepared(e) {
      if (
        e.rowType == "data" &&
        e.column.dataField == "bestellmenge" &&
        e.data.bestellmenge > 0
      ) {
        e.cellElement.classList.add("eingabe");
      }
    },

    /**
     * Bestellen & Warenkorb
     */

    fillWarenKorbWithData() {
      Helper.gridWarenkorbArtikel = Helper.gridAnzeige.filter(function (el) {
        return el.bestellmenge > 0;
      });
      Helper.gridAnzeige = Helper.gridWarenkorbArtikel;
      this.orderDataGridInstance.refresh();
    },

    disableUIComponents() {
      this.tourSelectBoxInstance.option("disabled", true);
      this.artikelGruppenSelectBoxInstance.option("disabled", true);
    },

    enableUIComponents() {
      this.tourSelectBoxInstance.option("disabled", false);
      this.artikelGruppenSelectBoxInstance.option("disabled", false);
    },

    warenkorbToggle() {
      if (Helper.warenkorbToggleStatus == false) {
        this.disableUIComponents();

        this.buttonWarenkorbInstance.option(
          "text",
          "Artikelübersicht"
        );
        this.artikelGruppenSelectBoxInstance.option("value", "Alle Artikel");

        this.orderDataGridInstance.option(
          "noDataText",
          "Sie haben noch keine Artikel Angeboten"
        );
        this.fillWarenKorbWithData();

        Helper.warenkorbToggleStatus = true;
      } else {
        this.buttonWarenkorbInstance.option(
          "text",
          "Angebotene Artikel"
        );

        this.enableUIComponents();
        this.filterOrderGridNachTour(Helper.selectBoxTourDaten[0].tourID);
        this.orderDataGridInstance.refresh();
        Helper.warenkorbToggleStatus = false;
      }
    },

    async anbieten() {

      if(Helper.anbietenDaten.length <= 0){
        notify("Keine Artikel zum anbieten ausgewählt.", "warning", 3000);
        return;
      }

      try {
        Helper.anbietenDaten.forEach((element) => {
          Helper.bieteArtikelAn(element, this.userData.token);
        });

        this.initMethod();
        notify("Angebot erfolgreich verschickt!", "success", 3000);
      } catch (error) {
        notify("Fehler: " + error, "error", 3000);
      }
    },

    /**
     * Display Expression Events
     */

    selectBoxTourDisplayExpression(item) {
      if (item !== null) {
        let dtm = new Date(item.datum);
        let strWochentag;
        switch (dtm.getDay()) {
          case 0:
            strWochentag = "So";
            break;
          case 1:
            strWochentag = "Mo";
            break;
          case 2:
            strWochentag = "Di";
            break;
          case 3:
            strWochentag = "Mi";
            break;
          case 4:
            strWochentag = "Do";
            break;
          case 5:
            strWochentag = "Fr";
            break;
          case 6:
            strWochentag = "Sa";
            break;
        }
        return (
          strWochentag +
          " " +
          item.datum.substring(8, 10) +
          "." +
          item.datum.substring(5, 7) +
          "." +
          item.datum.substring(0, 4)
        );
      }
    },

    /**
     * Grid Aktualisieren
     */

    async initMethod() {
      /**
       * Frage die Daten für die UI Komponenten an
       */
      this.orderDataGridInstance.option("loadPanel", { enabled: true });

      this.orderDataGridInstance.beginCustomLoading();
      await this.getUserData();
      await Helper.getTourData(this.userData.token);
      await Helper.getAlleArtikelDaten(this.userData.token);

      /**
       * Initiazles setzen von Optionen der UI Komponenten
       */

      this.tourSelectBoxInstance.option(
        "value",
        Helper.selectBoxTourDaten[0].tourID
      );

      this.filterOrderGridNachTour(Helper.selectBoxTourDaten[0].tourID);
      this.setzeArtikelGruppenDataSource();

      /**
       * Deaktiviere die Loader Animation nach laden des Tabels, -> mode: cell
       */

      Helper.originalBestellMenge = [];
      for (let index = 0; index < Helper.gridAlleArtikelDaten.length; index++) {
        Helper.originalBestellMenge.push({
          pK_ID: Helper.gridAlleArtikelDaten[index].pK_ID,
          bestellmenge: Helper.gridAlleArtikelDaten[index].bestellmenge,
        });
      }

      /**
       * Leere Datenspeicher
       */

      Helper.anbietenDaten = [];

      /** Initialen Offer-Status setzen */

      this.setOfferStatus();

      if (Helper.warenkorbToggleStatus == true) {
        this.warenkorbToggle();
      }

      this.orderDataGridInstance.endCustomLoading();
      this.orderDataGridInstance.option("loadPanel", { enabled: false });
    },

    async aktualisiereGrid() {
      await this.initMethod();

      notify("Aktualisierung Erfolgreich!", "success", 5000);
    },

    /**
     * Value Change Events
     */

    selectBoxArtikelGruppenValueChangedEvent(item) {
      this.filterOrderGridNachArtikelGruppe(item.value);
    },

    selectBoxTourValueChangedEvent(item) {
      this.filterOrderGridNachTour(item.value);
    },

    /**
     * Get User Data
     */

    async getUserData() {
      await auth.getUser().then((e) => {
        this.userData = e.data;
      });
    },

    /**
     * Filter Grid Functions
     * */

    setOfferStatus() {
      for (let index = 0; index < Helper.gridAlleArtikelDaten.length; index++) {
        if (Helper.gridAlleArtikelDaten[index].bestellmenge > 0) {
          this.statusSelectBoxInstance.option("value", "Angeboten");
          return;
        }
      }
      this.statusSelectBoxInstance.option("value", "Neu");
    },

    filterOrderGridNachArtikelGruppe(ChosenArtikelGruppe) {
      var objGridAnzeigeNeu = [];
      Helper.gridAnzeigeTour.forEach(function (item) {
        if (
          item.artikelgruppe === ChosenArtikelGruppe ||
          ChosenArtikelGruppe === "Alle Artikel"
        ) {
          objGridAnzeigeNeu.push(item);
        }
      });
      Helper.gridAnzeige = objGridAnzeigeNeu;
      this.orderDataGridInstance.refresh();
    },

    filterOrderGridNachTour(tourID) {
      Helper.gridAnzeige = Helper.gridAlleArtikelDaten.filter(function (el) {
        return el.tourID === tourID;
      });
      Helper.gridAnzeige.sort((a, b) => (a.sortierung < b.sortierung ? -1 : 1));
      Helper.gridAnzeigeTour = Helper.gridAnzeige;

      this.orderDataGridInstance.refresh();
    },

    setzeArtikelGruppenDataSource() {
      var artikelGruppen = ["Alle Artikel"];
      var gridAlleArtikelDatenCopy = Helper.gridAlleArtikelDaten;
      for (var i = 0; i < gridAlleArtikelDatenCopy.length; i++) {
        if (
          !artikelGruppen.includes(gridAlleArtikelDatenCopy[i].artikelgruppe)
        ) {
          artikelGruppen.push(gridAlleArtikelDatenCopy[i].artikelgruppe);
        }
      }
      Helper.selextBoxArtikelGruppenDaten = artikelGruppen;
    },

    /**
     * Cell Validator
     */

    revalidate() {
      console.log("custom callback");
    },

    /**
     * Zell Funktionen der Grid Spalten
     */

    kzVerfuegbarCellFunction(rowData) {
      if (rowData.kzVerfuegbar === 1) {
        return "";
      } else {
        return "Nicht verfügbar";
      }
    },
    mengeVerfuegbarCellFunction(rowData) {
      if (rowData.bestellteMenge < 0) {
        return "0 " + rowData.einheit;
      } else if (
        rowData.maxAnzeigeMenge < rowData.bestellteMenge &&
        rowData.maxAnzeigeMenge > 0
      ) {
        return rowData.maxAnzeigeMenge + " " + rowData.einheit;
      } else {
        return rowData.bestellteMenge + " " + rowData.einheit;
      }
    },
    mengeVPECellFunction(rowData) {
      return rowData.mengeVPE + " " + rowData.einheit;
    },
    eingabebestellmengeCellFunction(rowData) {
      if (rowData.bestellmenge > 0 && rowData.mengeVPE > 0) {
        return rowData.bestellmenge * rowData.mengeVPE + " " + rowData.einheit;
      } else if (
        typeof rowData.bestellmenge === "undefined" ||
        rowData.bestellmenge === 0
      ) {
        return "0" + " " + rowData.einheit;
      } else {
        return "0 " + rowData.einheit;
      }
    },
    preisCellFunction(rowData) {
      let einzelpreis = parseFloat(rowData.preis);
      return String(einzelpreis.toFixed(2)) + "€";
    },
  },
  computed: {
    orderDataGridInstance: function () {
      return this.$refs.orderDataGridComponent.instance;
    },
    tourSelectBoxInstance: function () {
      return this.$refs.selectBoxOptionsTourComponent.instance;
    },
    artikelGruppenSelectBoxInstance: function () {
      return this.$refs.selectBoxOptionsArtikelGruppenComponent.instance;
    },
    buttonWarenkorbInstance: function () {
      return this.$refs.buttonWarenkorb.instance;
    },
    statusSelectBoxInstance: function () {
      return this.$refs.selectBoxStatusComponent.instance;
    },
  },
};
</script>

<style>
.eingabe {
  background-color: #dcecca !important;
}
</style>
